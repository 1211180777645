import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import ProductAttributeGroup from './ProductAttributeGroup';
import PriceAndAvailability from './PriceAndAvailability';
import AddProduct from './AddProduct';
import ListPriceAndDiscount from './ListPriceAndDiscount';
import ImageHoverGalery from '../Image/ImageHoverGalery';
import YouTubeVideo from '../Video/YouTubeVideo';
import CustomLink from '../Link/CustomLink';
import ProductListItem from './ProductListItem';
import LinkButton from '../Button/LinkButton';
import Message from '../Message/Message';
import ProductAdditionalInfoMsgs from './ProductAdditionalInfoMsgs';
import arrayHelper from '../../utils/helper/array-helper';

export default function Product(props) {
  const intl = useIntl();
  function getInitialOrderQuantity() {
    if (props.customOrderQuantityInfo && props.customOrderQuantityInfo.default) {
      return props.customOrderQuantityInfo.default;
    }
    if (props.product) {
      return props.services.productService.getDefaultOrderQuantity(props.product);
    }
    return 1;
  }
  const [orderQuantity, setOrderQuantity] = useState(getInitialOrderQuantity());
  let productTypeConfig;
  const qtyChangeHandler = (newQty) => setOrderQuantity(newQty);
  useEffect(() => {
    if (props.product) {
      setOrderQuantity(getInitialOrderQuantity());
      props.services.apiService.int({ param1: 'prvi', param2: props.product.id });
    }
  }, [props.product]);
  if (props.product) {
    productTypeConfig = props.services.productService.getProductTypeConfigByProduct(props.product);
  } else {
    return (<strong>Product not defined</strong>);
  }
  const cadConfig = props.services.productService.getProductCadConfig();
  const positionPriceInfo = props.services.salesService.getPositionPriceInfo(
    props.product, orderQuantity, props.dataStorage, props.locale
  );
  const images = props.services.productService.getImages(
    productTypeConfig, props.product, props.locale
  );
  const videos = props.services.productService.getAdditionalInfoByLocale(
    productTypeConfig, props.product, 'videos', props.locale
  );
  const additionalTexts = props.services.productService.getAdditionalInfoByLocale(
    productTypeConfig, props.product, 'additionalTexts', props.locale
  );
  const hyperlinks = props.services.productService.getAdditionalInfoByLocale(
    productTypeConfig, props.product, 'hyperlinks', props.locale
  );
  function getFilteredMainAttributeGroup() {
    const filteredMainAttributeGroup = [];
    props.services.productService.getProductConfig().attributeGroups.main.forEach((
      mainListAttribute
    ) => {
      if (mainListAttribute.id !== 'weight'
        || (mainListAttribute.id === 'weight' && props.product.weight > 0)
      ) {
        if (!mainListAttribute.constraints) {
          filteredMainAttributeGroup.push(mainListAttribute.id);
        } else if (mainListAttribute.constraints.include
          && mainListAttribute.constraints.include.productTypeIds
          && mainListAttribute.constraints.include.productTypeIds.includes(
            props.product.productType.i18nId
          )
        ) {
          filteredMainAttributeGroup.push(mainListAttribute.id);
        } else if (mainListAttribute.constraints.exclude
          && mainListAttribute.constraints.exclude.productTypeIds
          && !mainListAttribute.constraints.exclude.productTypeIds.includes(
            props.product.productType.i18nId
          )
        ) {
          filteredMainAttributeGroup.push(mainListAttribute.id);
        }
      }
    });
    return filteredMainAttributeGroup;
  }
  const mainAttributeGroup = getFilteredMainAttributeGroup();
  function getRelatedProductItems(type) {
    if ((type === 'similar' && !props.showSimilarProducts) || (type === 'fitsTo' && !props.showFitsToProducts)) {
      return [];
    }
    return props.services.productService.getRelatedProducts(
      productTypeConfig, props.product, type, props.dataStorage.products, props.locale, 6
    );
  }
  const compatibleProducts = getRelatedProductItems('fitsTo');
  const similarProducts = getRelatedProductItems('similar');
  function getHeading() {
    if (props.isPopup) {
      return (<h2>{props.product.name[props.locale]}</h2>);
    }
    return (<h1>{props.product.name[props.locale]}</h1>);
  }
  function getTechnicalAttributes() {
    const technicalAttributes = props.services.productService.getAdditionalAttributeIds(
      props.product
    );
    arrayHelper.removeElements(technicalAttributes, mainAttributeGroup);
    return technicalAttributes;
  }
  const technicalAttributes = getTechnicalAttributes();
  function getAboveTheFoldAttributes() {
    if (productTypeConfig && productTypeConfig.listItemAttributes) {
      const attributeIds = productTypeConfig.listItemAttributes.map(
        (attributeConfig) => attributeConfig.id
      );
      return (
        <ProductAttributeGroup
          product={props.product}
          attributeIds={attributeIds}
          locale={props.locale}
          services={props.services}
        />
      );
    }
    return undefined;
  }
  function getAvailability() {
    const availabilityData = props.services.productService.getAvailabilityData(
      props.product, intl, orderQuantity
    );
    if (availabilityData.statusCode === 0) {
      return (<div className="product-view-availability-info"><strong>{availabilityData.label}</strong></div>);
    }
    return (
      <Fragment>
        <div className="product-view-availability-info"><strong>{availabilityData.label}</strong></div>
        <div className="product-view-procurement-time"><FormattedMessage id="withinXdays" values={{ days: props.product.procurementTime }} /></div>
      </Fragment>
    );
  }
  function getFeaturedImage() {
    return (
      <div className="product-featured-image-container">
        <div className="product-view-second-level-heading"><strong>{props.product.pageParams.featuredImage.alt[props.locale]}</strong></div>
        <img
          src={props.services.configService.getProductImgPath(
            props.product.pageParams.featuredImage.src
          )}
          alt={props.product.pageParams.featuredImage.alt[props.locale]}
          className="product-featured-image"
        />
      </div>
    );
  }
  function getVideos() {
    return (
      <div className="product-videos-container">
        {videos.map((video) => (
          <Fragment>
            <div className="product-view-second-level-heading"><strong>{video.title}</strong></div>
            <YouTubeVideo src={video.src} title={video.title} />
          </Fragment>
        ))}
      </div>
    );
  }
  function getHyperlinks() {
    return (
      <div className="product-hyperlinks-container">
        <div className="product-view-second-level-heading"><strong><FormattedMessage id="linksAndDownloads" /></strong></div>
        {hyperlinks.map((hyperlink) => (
          <div>
            <CustomLink
              href={hyperlink.href}
              target={hyperlink.target}
              isExternal={hyperlink.isExternal}
            >{hyperlink.label}
            </CustomLink>
          </div>
        ))}
        {(props.product.pageParams && props.product.pageParams.cadSource === 'tp') && (
          <div>
            <CustomLink
              href={`${props.services.configService.getPageByTranslationCodeAndLocale('cad-download', props.locale).path}?id=${props.product.id}`}
              target="_blank"
            ><FormattedMessage id="cadData" />
            </CustomLink>
          </div>
        )}
        {(props.product.pageParams && props.product.pageParams.cadSource === 'x' && cadConfig && Array.isArray(cadConfig.formats)) && (
          cadConfig.formats.map((format) => (
            <div>
              <CustomLink
                href={`${cadConfig.source}${format.name}?x=${props.product.id}.${format.extention}`}
                target="_blank"
                isExternal={true}
              ><FormattedMessage id="downloadInFormat" values={{ format: format.name }} />
              </CustomLink>
            </div>
          ))
        )}
      </div>
    );
  }
  function getAdditionalTexts() {
    return additionalTexts.map((row) => {
      if (row.heading) {
        return (<div className="product-view-second-level-heading"><strong>{row.heading}</strong></div>);
      } else if (row.text) {
        return (<div className="product-view-text">{row.text}</div>);
      }
      return (
        <div className="product-attribute-list">
          <div className="product-attribute-label">
            {row.label}:
          </div>
          <div className="product-attribute-values">
            {row.value}
          </div>
        </div>
      );
    });
  }
  function getRelatedProductList(itemList) {
    return (
      <div className="grid-product-list">
        {itemList.map((productItem) => (
          <ProductListItem
            services={props.services}
            dataStorage={props.dataStorage}
            locale={props.locale}
            currency={props.services.configService.getGeneralConfig().sales.currency}
            product={productItem}
            key={JSON.stringify(productItem)}
          />
        ))}
      </div>
    );
  }
  return (
    <div className="product-view-container">
      <div className="grid-product-view-top">
        <div className="product-view-image-container">
          <ImageHoverGalery images={images} />
        </div>
        <div className="product-view-top-info-container">
          {getHeading()}
          {productTypeConfig && getAboveTheFoldAttributes()}
          {(props.product.packagingUnit > 1) && (
            <div className="product-attribute-list">
              <div className="product-attribute-label">
                {props.services.productService.getTranslationOfBaseAttribute('packagingUnit', intl)}:
              </div>
              <div className="product-attribute-values">
                {`${props.product.packagingUnit} ${props.services.productService.getAttributeValueTranslation(
                  props.product.unitOfQuantity.i18nId, undefined, props.locale
                )}`}
              </div>
            </div>
          )}
          {!props.product.isOnlyOnRequest && (
            <div>
              {(props.services.configService.getGeneralConfig().sales.enableListPriceInfo
                && !props.dataStorage.user.firstname) && (
                <Message type="warning">
                  <FormattedMessage id="listPriceLoginInfo" />
                </Message>
              )}
              {positionPriceInfo.discount && (
                <ListPriceAndDiscount
                  listPrice={positionPriceInfo.formattedListPrice}
                  discount={positionPriceInfo.formattedDiscount}
                />
              )}
              <PriceAndAvailability
                formattedPrice={positionPriceInfo.formattedPrice}
                formattedQuantityAndUnit={props.services.productService.getFormattedQuantityAndUnit(
                  orderQuantity, props.product, props.locale
                )}
              >
                {getAvailability()}
              </PriceAndAvailability>
              <AddProduct
                product={props.product}
                qty={orderQuantity}
                showQuantity={props.showQuantity}
                addHandler={props.selectHandler}
                qtyChangeHandler={qtyChangeHandler}
                buttonTextTranslationId={props.buttonTextTranslationId}
                customOrderQuantityInfo={props.customOrderQuantityInfo}
                locale={props.locale}
                dataStorage={props.dataStorage}
                services={props.services}
              />
            </div>
          )}
          {(props.product.isOnlyOnRequest === true) && (
            <Message type="info">
              <FormattedMessage id="onlyOnRequestInfo" />
            </Message>
          )}
          {(props.product.pageParams
            && props.product.pageParams.infoBoxes
            && Array.isArray(props.product.pageParams.infoBoxes[props.locale])
            && props.product.pageParams.infoBoxes[props.locale].length > 0
          ) && (
            <ProductAdditionalInfoMsgs
              messages={props.product.pageParams.infoBoxes[props.locale]}
              locale={props.locale}
              services={props.services}
              dataStorage={props.dataStorage}
            />
          )}
        </div>
      </div>
      <div className="product-view-details-grid">
        <div className="product-view-attributes">
          <div className="product-view-second-level-heading"><strong><FormattedMessage id="productInformation" /></strong></div>
          <ProductAttributeGroup
            product={props.product}
            attributeIds={mainAttributeGroup}
            locale={props.locale}
            services={props.services}
          />
          {technicalAttributes.length > 0 && (<div className="product-view-second-level-heading"><strong><FormattedMessage id="technicalSpecifications" /></strong></div>)}
          <ProductAttributeGroup
            product={props.product}
            attributeIds={technicalAttributes}
            locale={props.locale}
            services={props.services}
          />
          {additionalTexts.length > 0 && (getAdditionalTexts())}
        </div>
        <div>
          {(
            props.product.pageParams
            && props.product.pageParams.featuredImage
          ) && (getFeaturedImage())}
          {videos.length > 0 && (getVideos())}
          {(hyperlinks.length > 0
            || (props.product.pageParams && props.product.pageParams.cadSource !== undefined)) && (
            getHyperlinks()
          )}
        </div>
      </div>
      {compatibleProducts.length > 0 && (
        <div className="compatible-products-container">
          <div className="product-view-second-level-heading"><strong><FormattedMessage id="productFitsTo" /></strong></div>
          {getRelatedProductList(compatibleProducts)}
          {(compatibleProducts.length === 6) && (
            <LinkButton
              href={`${props.services.configService.getPageByTranslationCodeAndLocale('combinable-products', props.locale).path}?id=${props.product.id}`}
              classNames="linkbutton linkbutton--full-width"
            >{intl.formatMessage({ id: 'showAll' })}
            </LinkButton>
          )}
        </div>
      )}
      {similarProducts.length > 0 && (
        <div className="compatible-products-container">
          <div className="product-view-second-level-heading"><strong><FormattedMessage id="productSimilarTo" /></strong></div>
          {getRelatedProductList(similarProducts)}
        </div>
      )}
    </div>
  );
}

Product.propTypes = {
  isPopup: PropTypes.bool,
  showQuantity: PropTypes.bool,
  showSimilarProducts: PropTypes.bool,
  showFitsToProducts: PropTypes.bool,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  selectHandler: PropTypes.func.isRequired,
  buttonTextTranslationId: PropTypes.string,
  customOrderQuantityInfo: PropTypes.objectOf(PropTypes.any),
  locale: PropTypes.string.isRequired,
  dataStorage: PropTypes.objectOf(PropTypes.any),
  services: PropTypes.objectOf(PropTypes.object).isRequired,
};

Product.defaultProps = {
  isPopup: false,
  showQuantity: true,
  showSimilarProducts: true,
  showFitsToProducts: true,
  buttonTextTranslationId: 'addToShoppingCart',
  customOrderQuantityInfo: undefined,
  dataStorage: undefined,
};
