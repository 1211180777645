import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Input from './Input';
import InvalidInputMessage from './InvalidInputMessage';
import './Form.css';

export default function FormInput(props) {
  const intl = useIntl();
  function getLabel() {
    if (props.showIsRequired) {
      return (
        <Fragment>
          <FormattedMessage id={props.label || props.id} /><span className="is-required-asterisk"> *</span>
        </Fragment>
      );
    } else if (props.labelIsInvisble) {
      return props.label || props.id;
    }
    return (<FormattedMessage id={props.label || props.id} />);
  }
  return (
    <Fragment>
      <div className={`grid-form__label${(props.labelIsInvisble) ? ' input-label-invisible' : ''}`}>
        <label htmlFor={props.id}>
          {getLabel()}
        </label>
      </div>
      <div className={`grid-form__input${(props.unit) ? ' grid-form__input--unit' : ''}`}>
        <Input
          id={props.id}
          type={props.type}
          classNames={props.isValid || (props.value === '' && props.noErrorMessageIfEmpty) ? undefined : 'invalid-value'}
          disabled={props.disabled}
          callback={props.callback}
          value={props.value}
          numberFormat={props.numberFormat}
          maxLength={props.maxLength}
        />
        {props.unit && (
          <div>{props.unit}</div>
        )}
      </div>
      <InvalidInputMessage
        display={!(props.isValid || (props.value === '' && props.noErrorMessageIfEmpty))}
        message={intl.formatMessage({ id: props.errorMessageId })}
      />
    </Fragment>
  );
}

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  errorMessageId: PropTypes.string.isRequired,
  noErrorMessageIfEmpty: PropTypes.bool,
  showIsRequired: PropTypes.bool,
  labelIsInvisble: PropTypes.bool,
  numberFormat: PropTypes.objectOf(PropTypes.object),
  maxLength: PropTypes.number,
  unit: PropTypes.string,
};

FormInput.defaultProps = {
  label: undefined,
  type: 'text',
  disabled: false,
  noErrorMessageIfEmpty: true,
  showIsRequired: false,
  labelIsInvisble: false,
  numberFormat: undefined,
  maxLength: undefined,
  unit: undefined,
};
